import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import LineWhite from '../../images/linewhite.png'
import './HappyPatients.css'

function HappyPatients() {
  return (
    <>
      <PageBanner pagename={"Happy Patients"} info={"At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages."} />
      <div className="review-points">
        <div className="review-points-1">
          <h2>Aisha Patel</h2>
          <h3>Mumbai</h3>
          <img src={LineWhite} alt="" />
          <p>"Ivory Dental Clinic completely transformed my smile! The staff is incredibly friendly and professional. They made me feel comfortable from start to finish. I couldn’t be happier with the results!"</p>
        </div>
        <div className="review-points-1">
          <h2>Rajesh Nair</h2>
          <h3>Navi Mumbai</h3>
          <img src={LineWhite} alt="" />
          <p>"Dr. [Dentist’s Name] is the best dentist I’ve ever had. Their attention to detail and commitment to patient care is outstanding. I always feel at ease knowing I’m in good hands."</p>
        </div>
        <div className="review-points-1">
          <h2>Sneha Patil</h2>
          <h3>Thane</h3>
          <img src={LineWhite} alt="" />
          <p>"I took my son to Ivory Dental Clinic for his first dental visit, and the experience was amazing. The pediatric dentist was so gentle and made him feel at ease. We’ll definitely be coming back!"</p>
        </div>
        <div className="review-points-1">
          <h2>Arjun Shinde</h2>
          <h3>Pune</h3>
          <img src={LineWhite} alt="" />
          <p>"I had been delaying dental work for years, but the team at Ivory Dental Clinic made it easy and stress-free. The care I received was top-notch, and I’m thrilled with the results."</p>
        </div>
        <div className="review-points-1">
          <h2>Priya Deshmukh</h2>
          <h3>Panvel</h3>
          <img src={LineWhite} alt="" />
          <p>"The entire staff at Ivory Dental Clinic is fantastic! From the moment you walk in, you’re treated with care and respect. My teeth have never looked better, thanks to their expert care."</p>
        </div>
        <div className="review-points-1">
          <h2>Vikram Sharma</h2>
          <h3>Belapur</h3>
          <img src={LineWhite} alt="" />
          <p>"I had an emergency and needed a tooth extraction. Ivory Dental Clinic saw me right away and made the process quick and painless. I’m so grateful for their swift and professional care."</p>
        </div>
        
      </div>
    </>
  )
}

export default HappyPatients
