import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
      <div className="main-footer">
        <div className="box1">
          <h2>Quick Links</h2>
          <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
          <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
          <li><NavLink to="/treatments" className={() => `navlink`}>Treatments</NavLink></li>
          <li><NavLink to="/cases" className={() => `navlink`}>Cases</NavLink></li>
          <li><NavLink to="/happy-patients" className={() => `navlink`}>Happy Patients</NavLink></li>
          <li><NavLink to="/appointment-book" className={() => `navlink`}>Book Appointment</NavLink></li>
          <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
        </div>
        <div className="box1">
          <h2>Treatments</h2>
          <li><NavLink to="/teeth-whitening" className={() => `navlink`}>Teeth Whitening</NavLink></li>
          <li><NavLink to="/dental-implants" className={() => `navlink`}>Dental Implants</NavLink></li>
          <li><NavLink to="/braces-and-aligners" className={() => `navlink`}>Braces & Aligners</NavLink></li>
          <li><NavLink to="/root-canal-treatment" className={() => `navlink`}>Root Canal Therapy</NavLink></li>
          <li><NavLink to="/pediatric-dentistry" className={() => `navlink`}>Pediatric Dentistry</NavLink></li>
          <li><NavLink to="/cosmetic-dentistry" className={() => `navlink`}>Cosmetic Dentistry</NavLink></li>
          <li><NavLink to="/gum-care" className={() => `navlink`}>Gum Care</NavLink></li>
          <li><NavLink to="/oral-surgery" className={() => `navlink`}>Oral Surgery</NavLink></li>
          <li><NavLink to="/hair-transplant" className={() => `navlink`}>Hair Transplant</NavLink></li>
          <li><NavLink to="/prp-hair-and-face" className={() => `navlink`}>PRP Hair & Face</NavLink></li>
          <li><NavLink to="/mole-removal-on-face" className={() => `navlink`}>Mole Removal On Face</NavLink></li>
          <li><NavLink to="/cleft-lip-and-palate-repair" className={() => `navlink`}>Cleft Lip & Palate Repair</NavLink></li>
        </div>
        <div className="box3">
          <h2>Connect With Us</h2>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-location-dot"></i>
              <p>Sector Number 9, Vighnaharta Society, Shop Number 3, Plot Number 3, opposite Maharashtra Bank, Kamothe, Mumbai, Maharashtra 410209</p>
            </div>
          </li>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-phone"></i>
              <p>+91 82084 27525</p>
            </div>
          </li>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-phone"></i>
              <p>admin@ivorydental.com</p>
            </div>
          </li>
        </div>
        <div className="box4">
          <h2>Reach Us</h2>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.002173701335!2d73.09221117524898!3d19.019625882173422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e9d15933bf89%3A0xb8a6b600fd78d1a7!2sIvory%20Dental%20%26%20Maxillofacial%20Clinic!5e0!3m2!1sen!2sin!4v1723447415010!5m2!1sen!2sin" title='ivory' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="bottom-footer">
        <h3>&copy; Ivory Dental Clinic - All Rights Reserved</h3>
        <h3>Designed By Himali & Shekhar</h3>
      </div>
    </footer>
  )
}

export default Footer
