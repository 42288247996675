import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import './Contact.css'
import Line2 from '../../images/line.png'

function Contact() {
  return (
    <>
      <PageBanner pagename={"Contact"} info={"At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages."} />
      <div className="contact-info">
        <div className="contact-info-box">
          <i className="fa-solid fa-phone"></i>
          <p>+91 74047 94047</p>
        </div>
        <div className="contact-info-box">
          <i className="fa-solid fa-location-dot"></i>
          <p>Sector Number 9, Vighnaharta Society, Shop Number 3, Plot Number 3, opposite Maharashtra Bank, Kamothe, Mumbai, Maharashtra 410209</p>
        </div>
        <div className="contact-info-box">
          <i className="fa-solid fa-phone"></i>
          <p>admin@ivorydentalclinic.com</p>
        </div>
      </div>
      <div className="contact-form">
        <form action="" className='form'>
          <h2>Please Share Your Feedback...!</h2>
          <img className="line" src={Line2} alt="" />
          <input type="text" placeholder='Enter Your Name' />
          <input type="email" placeholder='Enter Your Email' />
          <input type="Phone" placeholder='Enter Your Name' />
          <input type="Location" placeholder='Enter Your Location' />
          <textarea cols={5} placeholder='Enter Your Feedback'></textarea>
        </form>
      </div>
      <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.002173701335!2d73.09221117524898!3d19.019625882173422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e9d15933bf89%3A0xb8a6b600fd78d1a7!2sIvory%20Dental%20%26%20Maxillofacial%20Clinic!5e0!3m2!1sen!2sin!4v1723447415010!5m2!1sen!2sin" title='ivory' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  )
}

export default Contact
