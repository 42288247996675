import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'

import Treatment from '../../components/Treatment/Treatment'


import TeethW from '../../images/teeth-white.jpg'
import Dental from '../../images/dental-implants.jpg'
import Braces from '../../images/braces.jpg'
import Root from '../../images//root-canal.jpg'
import Pediatric from '../../images/pediatric-dentistry.jpg'
import Cosmatic from '../../images/cosmatic.jpg'
import GumCare from '../../images/gumCare.jpg'
import Oral from '../../images/oral-surgery.jpg'
import HairTransplant from '../../images/HairTrans.jpg'
import PRP from '../../images/PRP.jpg'
import MoleRemoval from '../../images/moleRemoval.jpg'
import CleftLip from '../../images/cleftLip.jpg'

function Treatments() {
  return (
    <>
      <PageBanner pagename={"Treatments"} info={"At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages."} />
      <div className="treatments" id='treatments'>
        <Treatment heading={"Teeth Whitening"} description={"Achieve a radiant smile with our professional teeth whitening services. We use safe, effective methods to remove stains and brighten your teeth for a dazzling result."} nextLink={"/teeth-whitening"} img={TeethW} />

        <Treatment heading={"Dental Implants"} description={"Restore missing teeth with our advanced dental implants. Designed to look and function like natural teeth, implants offer a permanent, comfortable solution to enhance your smile."} nextLink={"/dental-implants"} img={Dental} />

        <Treatment heading={"Braces & Aligners"} description={"Straighten your teeth discreetly with clear aligners or opt for traditional braces. Our orthodontic treatments are customized to ensure a perfect fit and effective results."} nextLink={"/braces-and-aligners"} img={Braces} />

        <Treatment heading={"Root Canal Therapy"} description={"Save your damaged or infected tooth with our gentle root canal therapy. We ensure minimal discomfort while effectively treating the root to preserve your natural tooth."} nextLink={"/root-canal-treatment"} img={Root} />

        <Treatment heading={"Pediatric Dentistry"} description={"Provide your child with a positive dental experience. Our pediatric services are tailored to meet children’s needs, from gentle cleanings to preventive care, ensuring a healthy smile."} nextLink={"/pediatric-dentistry"} img={Pediatric} />

        <Treatment heading={"Cosmetic Dentistry"} description={"Transform your smile with our cosmetic dental services. Whether through veneers, bonding, or other treatments, we enhance the aesthetics of your teeth for a beautiful, confident smile."} nextLink={"/cosmetic-dentistry"} img={Cosmatic} />

        <Treatment heading={"Gum Care"} description={"Maintain healthy gums with our comprehensive gum care services. We diagnose and treat gum disease, offering preventive care to ensure your gums remain strong and disease-free."} nextLink={"/gum-care"} img={GumCare} />

        <Treatment heading={"Oral Surgery"} description={"Experience safe and precise oral surgery with our expert team. From tooth extractions to wisdom teeth removal, we provide expert care to address your oral health needs effectively."} nextLink={"/oral-surgery"} img={Oral} />

        <Treatment heading={"Hair Transplant"} description={"Regain your confidence with our advanced hair transplant procedures."} nextLink={"/hair-transplant"} img={HairTransplant} />

        <Treatment heading={"PRP Hair & Face"} description={"Platelet-Rich Plasma (PRP) therapy is a revolutionary treatment that rejuvenates both skin and hair."} nextLink={"/prp-hair-and-face"} img={PRP} />

        <Treatment heading={"Mole Removal on Face"} description={"We offer safe and effective mole removal treatments to enhance your facial aesthetics. "} nextLink={"/mole-removal-on-face"} img={MoleRemoval} />

        <Treatment heading={"Cleft Lip & Palate Repair"} description={"We provide cleft lip and palate repair surgery, restoring both function and appearance for affected individuals."} nextLink={"/cleft-lip-and-palate-repair"} img={CleftLip} />

      </div>
    </>
  )
}

export default Treatments;
