import React from 'react'
import './SingleTreatment.css'
import Line from '../../images/line.png'
import { Link } from 'react-router-dom'

function SingleTreatment(props) {
  return (
    <div className='single-treatment-container'>
      <img src={props.image} alt="Teeth" className='main-img' />
      <h1>{props.heading}</h1>
      <img src={Line} alt="" className='line-treatment' />
      <p>{props.description}</p>
      {/* <h2>Treatment Cost : <span>{props.treatmentCost}/- Rs.</span></h2> */}
      <div className="knowMore">
      <button><Link to="tel:+91 82084 27525" >Call Us To Book Appointment</Link></button>
      </div>
    </div>
  )
}

export default SingleTreatment
