import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import Treatments from './pages/Treatments/Treatments';
import Contact from './pages/Contact/Contact';
import HappyPatients from './pages/HappyPatients/HappyPatients';
import AppointmentBook from './pages/AppointmentBook/AppointmentBook';
import SingleTreatment from './components/SingleTreatment/SingleTreatment';

import TeethW from './images/teeth-white.jpg'
import Dental from './images/dental-implants.jpg'
import Braces from './images/braces.jpg'
import Root from './images//root-canal.jpg'
import Pediatric from './images/pediatric-dentistry.jpg'
import Cosmatic from './images/cosmatic.jpg'
import GumCare from './images/gumCare.jpg'
import Oral from './images/oral-surgery.jpg'
import HairTransplant from './images/HairTrans.jpg'
import PRP from './images/PRP.jpg'
import MoleRemoval from './images/moleRemoval.jpg'
import CleftLip from './images/cleftLip.jpg'
import Cases from './pages/Cases/Cases';
import ScrollToTop from './components/ScrollToTop/Scroll';
import Preloader from './components/Preloader/Preloader';



function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show the preloader for 2 seconds whenever the URL changes
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [location]);
  return (
    <>
    {loading && <Preloader />} {/* Show preloader only when loading */}
    <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/treatments' element={<Treatments />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/happy-patients' element={<HappyPatients />} />
        <Route path='/appointment-book' element={<AppointmentBook />} />
        <Route path='/cases' element={<Cases />} />


        <Route path='/teeth-whitening'
          element={<SingleTreatment
            heading={"Teeth Whitening"}
            description={"Brighten your smile with our professional teeth whitening services. Our clinic offers both in-office and at-home whitening options to remove stubborn stains and discoloration. Using advanced whitening technology and safe, effective treatments, we ensure that your teeth achieve a radiant, natural-looking whiteness. Whether you’re preparing for a special event or simply want to enhance your daily smile, our teeth whitening solutions are designed to deliver outstanding results with minimal sensitivity."} treatmentCost={"1200"}
            image={TeethW} />} />

        <Route path='/dental-implants'
          element={<SingleTreatment
            heading={"Dental Implants"}
            description={"Restore your missing teeth with our state-of-the-art dental implants. This advanced treatment involves placing a titanium post into the jawbone, which acts as a sturdy foundation for a lifelike dental crown. Implants are a long-lasting, natural-looking solution that mimics the function and appearance of real teeth. Our skilled team uses the latest techniques and materials to ensure that your implants are seamlessly integrated and provide a comfortable, functional replacement for lost teeth."} treatmentCost={"6000"}
            image={Dental} />} />

        <Route path='/braces-and-aligners'
          element={<SingleTreatment
            heading={"Braces & Aligners"}
            description={"Achieve a straighter smile with our orthodontic treatments. We offer both traditional metal braces and clear aligners to accommodate your preferences and dental needs. Braces are designed to correct misalignments and improve bite issues, while clear aligners provide a more discreet option for teeth straightening. Our orthodontic solutions are customized to ensure the best fit and effectiveness, helping you achieve a well-aligned, confident smile with minimal impact on your daily life."} treatmentCost={"12000"}
            image={Braces} />} />

        <Route path='/root-canal-treatment'
          element={<SingleTreatment
            heading={"Root Canal Therapy"}
            description={"Save your damaged or infected tooth with our expert root canal therapy. This procedure involves removing the infected pulp from inside the tooth, cleaning and disinfecting the root canals, and sealing them to prevent further infection. Root canal therapy is a highly effective way to alleviate pain, preserve your natural tooth, and restore its functionality. Our team uses advanced techniques and anesthesia to ensure a comfortable experience, allowing you to maintain your tooth and its natural function."} treatmentCost={"6000"}
            image={Root} />} />

        <Route path='/pediatric-dentistry'
          element={<SingleTreatment
            heading={"Pediatric Dentistry"}
            description={"Provide your child with the best start to lifelong dental health with our pediatric dentistry services. We specialize in gentle, child-friendly care designed to make dental visits a positive experience. From routine cleanings and exams to preventive treatments and early orthodontic evaluations, our approach is tailored to meet the unique needs of growing smiles. We focus on education and preventive care to help your child develop healthy oral hygiene habits and maintain a beautiful smile."} treatmentCost={"10000"}
            image={Pediatric} />} />

        <Route path='/cosmetic-dentistry'
          element={<SingleTreatment
            heading={"Cosmetic Dentistry"}
            description={"Enhance the appearance of your smile with our comprehensive cosmetic dentistry services. Whether you’re interested in veneers, bonding, or other aesthetic treatments, we offer personalized solutions to address your cosmetic concerns. Our treatments are designed to improve the shape, color, and alignment of your teeth, helping you achieve a beautiful, confident smile. We work closely with you to understand your goals and deliver results that enhance your overall appearance and boost your self-esteem."} treatmentCost={"15000"}
            image={Cosmatic} />} />

        <Route path='/gum-care'
          element={<SingleTreatment
            heading={"Gum Care"}
            description={"Maintain optimal gum health with our specialized gum care services. We offer comprehensive treatment for gum diseases such as gingivitis and periodontitis, including deep cleanings, scaling, and root planing. Our goal is to address any existing issues, prevent further gum deterioration, and ensure that your gums remain healthy and strong. We provide personalized care plans and education on proper oral hygiene practices to support long-term gum health and overall oral wellness."} treatmentCost={"8000"}
            image={GumCare} />} />


        <Route path='/oral-surgery'
          element={<SingleTreatment
            heading={"Oral Surgery"}
            description={"Receive expert care for a range of oral surgery needs at our clinic. Our oral surgery services include tooth extractions, wisdom teeth removal, and other surgical procedures aimed at improving oral health. We use advanced techniques and technology to ensure precise, effective results while prioritizing your comfort and recovery. Our experienced team provides thorough pre-surgical consultations and aftercare to support a smooth, successful outcome and address any concerns you may have."} treatmentCost={"8000"}
            image={Oral} />} />

        <Route path='/hair-transplant'
          element={<SingleTreatment
            heading={"Hair Transplant"}
            description={"Hair loss can impact both your appearance and confidence. At Ivory Dental Clinic, we offer FUE (Follicular Unit Extraction) and FUT (Follicular Unit Transplantation) hair transplant methods to help you regain your natural hair.Our advanced hair transplant procedures are minimally invasive, using cutting-edge techniques that ensure natural-looking results with minimal downtime. The treatment involves harvesting healthy hair follicles from donor areas and implanting them in areas of hair thinning or baldness.With a high success rate, this treatment is suitable for both men and women experiencing hair loss due to various factors, including genetics, hormonal changes, or aging. We provide a personalized consultation to evaluate your hair loss condition and recommend the most effective treatment plan for you."} treatmentCost={"8000"}
            image={HairTransplant} />} />

        <Route path='/prp-hair-and-face'
          element={<SingleTreatment
            heading={"PRP Hair & Face"}
            description={"PRP (Platelet-Rich Plasma) therapy is a breakthrough in hair and skin rejuvenation. Using the body’s own healing mechanisms, PRP treatments stimulate cell growth and tissue repair, making it a highly effective solution for both hair and skin rejuvenation.PRP for Hair: For individuals facing hair thinning or early stages of baldness, PRP therapy boosts hair follicle health, promoting thicker and healthier hair growth. A small amount of your blood is drawn, processed to isolate the platelet-rich plasma, and injected into the scalp. The platelets encourage natural hair growth by improving blood supply and strengthening hair follicles.PRP for Face: This procedure, also known as the “Vampire Facial,” is widely used for facial rejuvenation. The PRP is injected into targeted areas of the face, boosting collagen production and enhancing skin elasticity. It effectively reduces fine lines, wrinkles, and acne scars, leaving you with smoother, radiant skin."} treatmentCost={"8000"}
            image={PRP} />} />

        <Route path='/mole-removal-on-face'
          element={<SingleTreatment
            heading={"Mole Removal on Face"}
            description={"At Ivory Dental Clinic, we understand that facial moles can be both a cosmetic concern and a health risk. We offer safe and effective mole removal procedures that minimize scarring while restoring the natural appearance of your skin.Using advanced techniques such as laser treatment or surgical excision, we ensure that the procedure is quick and painless. Our team evaluates each mole to determine the most appropriate removal method, ensuring optimal cosmetic results. The treatment is suitable for both benign moles and those requiring removal for medical reasons."} treatmentCost={"8000"}
            image={MoleRemoval} />} />

        <Route path='/cleft-lip-and-palate-repair'
          element={<SingleTreatment
            heading={"Cleft Lip & Palate Repair"}
            description={"Cleft lip and palate conditions can affect a person's ability to speak, eat, and breathe properly. At Ivory Dental Clinic, we specialize in reconstructive surgeries to correct these congenital defects. Our experienced surgeons perform cleft lip and palate repair procedures, helping individuals regain normal function and appearance.The surgery typically involves the reconstruction of the lip and palate to restore the proper function of the mouth and nose. Our team works closely with patients to ensure that they receive the highest standard of care, from the initial consultation to postoperative recovery. In many cases, early intervention can lead to remarkable improvements in speech, eating habits, and overall quality of life."} treatmentCost={"8000"}
            image={CleftLip} />} />


      </Routes>
      <Footer />
    </>
  );
}

export default App;
