import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import Logo from '../../images/ivory-logo.png'
import Line from '../../images/line.png'
import './Header.css'

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <header className='header'>
            <div className="mobileTop">
                <div className="phone">
                    <Link to={"tel:+91 82084 27525"}><i className="fa-solid fa-phone"></i></Link>
                    <Link to={"tel:+91 82084 27525"}><p>+91 82084 27525</p></Link>
                </div>
                <div className="icons">
                    <Link to={"https://wa.link/73nj80"} target='mib'><i className="fa-brands fa-whatsapp"></i></Link>
                    <Link to={"https://maps.app.goo.gl/d8UgpH2YNyYusGoSA"} target='ivory'><i class="fa-solid fa-location-dot"></i></Link>
                </div>
            </div>
            <div className="topNavbar">
                <div className="left-topNavbar">
                    <div className="iconInfo">
                        <i className="fa-solid fa-location-dot"></i>
                        <p>Sector 9, Vighnaharta Society, Shop 3, Plot 3, Opposite Maharashtra Bank, Kamothe, 410209</p>
                    </div>

                    <div className="iconInfo iconInfoPhone">
                        <i className="fa-solid fa-phone"></i>
                        <p>+91 82084 27525</p>
                    </div>


                </div>
                <div className="right-topNavbar">
                    <i class="fa-brands fa-facebook"></i>
                    <i class="fa-brands fa-instagram"></i>
                </div>
            </div>
            <div className={isMenuOpen ? "mobileMenu open" : "close"}>
                <li><NavLink to="/" className={() => `navlink`} onClick={toggleMenu}>Home</NavLink></li>
                <li><NavLink to="/about" className={() => `navlink`} onClick={toggleMenu}>About Us</NavLink></li>
                <li><NavLink to="/treatments" className={() => `navlink`} onClick={toggleMenu}>Treatments</NavLink></li>
                <li><NavLink to="/cases" className={() => `navlink`} onClick={toggleMenu}>Cases</NavLink></li>
                <li><NavLink to="/happy-patients" className={() => `navlink`} onClick={toggleMenu}>Happy Patients</NavLink></li>
                <li><NavLink to="/appointment-book" className={() => `navlink`} onClick={toggleMenu}>Book Appointment</NavLink></li>
                <li><NavLink to="/contact" className={() => `navlink`} onClick={toggleMenu}>Contact Us</NavLink></li>
            </div>
            <div className="navbar">
                <div className="center-navbar">
                    <img className="logo" src={Logo} alt="" />
                    <img className="line" src={Line} alt="" />
                </div>

                <div className="left-navbar">
                    <ul >
                        <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
                        <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
                        <li><NavLink to="/treatments" className={() => `navlink`}>Treatments</NavLink></li>
                        <li><NavLink to="/cases" className={() => `navlink`}>Cases</NavLink></li>
                        <li><NavLink to="/happy-patients" className={() => `navlink`}>Happy Patients</NavLink></li>
                        <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
                    </ul>
                </div>

                <div className="right-navbar">
                    <button><Link to="https://wa.link/73nj80" >WhatsApp Us</Link></button>
                    <button><Link to="tel:+91 82084 27525" >Call Now</Link></button>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
                </div>
            </div>
        </header>
    )
}

export default Header
