import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import dentist from '../../images/dentist-about.jpg'
import Line2 from '../../images/line.png'
import LineWhite from '../../images/linewhite.png'
import './About.css'

function About() {
  return (
    <>
      <PageBanner pagename={"About Us"} info={"At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages."} />
      <div className="about-us">
        <img src={dentist} alt="" className='aboutImg' />
        <div className="about-us-info">
          <h1>Ivory Dental Clinic</h1>
          <img className="line" src={Line2} alt="" />
          <h2>Transforming Smiles, Enhancing Lives. </h2>
          <p>At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages. With state-of-the-art technology and a team of highly skilled professionals, we ensure that every visit is comfortable, personalized, and effective.
          </p>
        </div>
      </div>
      <div className="about-points">
        <div className="about-points-1">
          <h2>Personalized Care</h2>
          <img src={LineWhite} alt="" />
          <p>At Ivory Dental Clinic, we believe that every patient is unique. Our team takes the time to understand your specific needs and concerns, crafting personalized treatment plans that align with your goals. Your comfort and satisfaction are our top priorities, ensuring that you receive care tailored just for you.</p>
        </div>
        <div className="about-points-1">
          <h2>Comfortable Environment</h2>
          <img src={LineWhite} alt="" />
          <p>We know that visiting the dentist can be stressful, which is why we’ve designed our clinic to be a welcoming and relaxing space. From the soothing ambiance to our friendly staff, every aspect of your visit is focused on making you feel at ease. Enjoy a stress-free dental experience every time.</p>
        </div>
        <div className="about-points-1">
          <h2>Experienced Professionals</h2>
          <img src={LineWhite} alt="" />
          <p>Our team of highly qualified dentists and hygienists brings years of experience and expertise to every patient interaction. We stay updated on the latest advancements in dental care to ensure that you receive the most effective and up-to-date treatments. Trust in our experience for exceptional results.</p>
        </div>
      </div>
    </>
  )
}

export default About
