import React from 'react'
import { Link } from 'react-router-dom'
import PageBanner from '../../components/PageBanner/PageBanner'
import './AppointmentBook.css'
import Line from '../../images/line.png'
import Dent from '../../images/ivory-logo.png'

function AppointmentBook() {
  return (
    <>
    <PageBanner pagename={"Book An Appointment"} info={"At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages."}/>
    <div className="order-now">
        <img src={Dent} alt="" />
        <h2>Call Now To Book An Appointment</h2>
        <img className="line" src={Line} alt="" />
        <Link to={"tel:+91 82084 27525"} target='mib'><h1>+91 82084 27525</h1></Link>
        <br /><br />
        <h2>Whatsapp Us On </h2>
        <img className="line" src={Line} alt="" />
        <Link to={"https://wa.link/73nj80"} target='mib'><h1>+91 98902 78101</h1></Link>
    </div>
    </>
  )
}

export default AppointmentBook
