import React from 'react'
import './Treatment.css'
import { NavLink } from 'react-router-dom'
import About from '../../images/dentist-about.jpg'

function Treatment(props) {
    return (
        <div className="treatM">
            <img src={props.img} alt="" />
            <div className="treatment-desc">
                <h3>{props.heading}</h3>
                {/* <img className="line" src={Line} alt="" /> */}
                <p>{props.description}</p>
                <button><NavLink to={props.nextLink}>Know More</NavLink></button>
            </div>
        </div>
    )
}

export default Treatment
