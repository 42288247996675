import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import About from '../../images/dentist-about.jpg'
import Line from '../../images/linewhite.png'
import dent from '../../images/image2.png'
import Line2 from '../../images/line.png'
import Treatment from '../../components/Treatment/Treatment'

import TeethW from '../../images/teeth-white.jpg'
import Dental from '../../images/dental-implants.jpg'
import Braces from '../../images/braces.jpg'
import Root from '../../images//root-canal.jpg'
import Pediatric from '../../images/pediatric-dentistry.jpg'
import Cosmatic from '../../images/cosmatic.jpg'
import GumCare from '../../images/gumCare.jpg'
import Oral from '../../images/oral-surgery.jpg'
import HairTransplant from '../../images/HairTrans.jpg'
import PRP from '../../images/PRP.jpg'
import MoleRemoval from '../../images/moleRemoval.jpg'
import CleftLip from '../../images/cleftLip.jpg'





function Home() {
    return (
        <>
            <main>
                <div className="home-container">
                    <div className="home-image">
                        <img src={dent} alt="" />
                    </div>
                    <div className="home-container-text">
                        <h2>Welcome To</h2>
                        <img className="line" src={Line} alt="" />
                        <h1>Ivory Dental & Maxillofacial Clinic</h1>
                        <p>At Ivory Dental Clinic, your smile is our priority. Located in the heart of Kamothe, our clinic is dedicated to providing exceptional dental care for patients of all ages. With state-of-the-art technology and a team of highly skilled professionals, we ensure that every visit is comfortable, personalized, and effective.
                        </p>

                        <div className="buttonsDiv">
                            <button className='redBtn'><Link to="/appointment-book" >Book An Appointment</Link></button>
                            <button className='whiteBtn'><Link to="/treatments" >View Treatments</Link></button>
                        </div>
                    </div>


                </div>
            </main>
            <div className="about-container">
                <div className="about-image">
                    <img src={About} alt="" />
                </div>
                <div className="about-text">
                    <h2>Healthy Teeth, Confident Smiles.</h2>
                    <img className="line" src={Line2} alt="" />
                    <p>At Ivory Dental Clinic, we are committed to providing top-quality dental care in a friendly and comfortable environment. Our team of experienced dentists offers a wide range of dental services to help you achieve and maintain a healthy, beautiful smile.
                    </p>

                    <div className="points">
                        <div className="point">
                            <i class="fa-brands fa-sketch"></i>
                            <h3>Expertise</h3>
                            <p>With over 10 years of dental excellence.</p>
                        </div>
                        <div className="point">
                            <i class="fa-brands fa-phoenix-framework"></i>
                            <h3>Precision</h3>
                            <p>Cutting-edge tools for accurate treatments.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="heading choose">
                    <h2>Why Choose Us</h2>
                    <img src={Line2} alt="" />
                    <p>Expertise & Experience</p>
                </div>
            <div className="why-us">
                <div className="why-us-1">
                    <i class="fa-solid fa-user-doctor"></i>
                    <h3>Experienced Team</h3>
                    <p>Our dentists and staff are highly trained and committed to providing the best care possible.</p>
                </div>
                <div className="why-us-1">
                    <i class="fa-solid fa-desktop"></i>
                    <h3>Advanced Technology</h3>
                    <p>We use the latest dental technology to ensure accurate diagnoses and effective treatments.</p>
                </div>
                <div className="why-us-1">
                    <i class="fa-solid fa-heart-circle-check"></i>
                    <h3>Patient-Centered Care</h3>
                    <p>We take the time to understand your needs and tailor our services to meet them.</p>
                </div>
            </div>


            <div className="treatmentsWrapper">
                <div className="heading">
                    <h2>Treatments</h2>
                    <img src={Line2} alt="" />
                    <p>Treatments provided by us</p>
                </div>
                <div className="treatments" id='treatments'>
                    <Treatment heading={"Teeth Whitening"} description={"Achieve a radiant smile with our teeth whitening services."} nextLink={"/teeth-whitening"} img={TeethW} />

                    <Treatment heading={"Dental Implants"} description={"Restore missing teeth with our advanced dental implants."} nextLink={"/dental-implants"} img={Dental} />

                    <Treatment heading={"Braces & Aligners"} description={"Straighten your teeth discreetly with clear aligners."} nextLink={"/braces-and-aligners"} img={Braces} />

                    <Treatment heading={"Root Canal Therapy"} description={"Save your damaged or infected tooth with our root canal therapy."} nextLink={"/root-canal-treatment"} img={Root} />

                    <Treatment heading={"Pediatric Dentistry"} description={"Provide your child with a positive dental experience."} nextLink={"/pediatric-dentistry"} img={Pediatric} />

                    <Treatment heading={"Cosmetic Dentistry"} description={"Transform your smile with our cosmetic dental services."} nextLink={"/cosmetic-dentistry"} img={Cosmatic} />

                    <Treatment heading={"Gum Care"} description={"Maintain healthy gums with our comprehensive gum care services."} nextLink={"/gum-care"} img={GumCare} />

                    <Treatment heading={"Oral Surgery"} description={"Experience safe and precise oral surgery with our expert team."} nextLink={"/oral-surgery"} img={Oral} />
                    
                    <Treatment heading={"Hair Transplant"} description={"Regain your confidence with our advanced hair transplant procedures."} nextLink={"/hair-transplant"} img={HairTransplant} />

                    <Treatment heading={"PRP Hair & Face"} description={"Platelet-Rich Plasma (PRP) therapy is a revolutionary treatment that rejuvenates both skin and hair."} nextLink={"/prp-hair-and-face"} img={PRP} />

                    <Treatment heading={"Mole Removal on Face"} description={"We offer safe and effective mole removal treatments to enhance your facial aesthetics. "} nextLink={"/mole-removal-on-face"} img={MoleRemoval} />

                    <Treatment heading={"Cleft Lip & Palate Repair"} description={"We provide cleft lip and palate repair surgery, restoring both function and appearance for affected individuals."} nextLink={"/cleft-lip-and-palate-repair"} img={CleftLip} />

                </div>
                <div className="knowMore">
                    <button><Link to="tel:+91 82084 27525" >Call Us To Book Appointment</Link></button>
                </div>
            </div>




        </>
    )
}

export default Home
