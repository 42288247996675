import React from 'react'
import './Cases.css'
import PageBanner from '../../components/PageBanner/PageBanner'
import Line from '../../images/line.png'
import implantPlacement from '../../images/implantPlacement.jpeg'
import clinicalView from '../../images/ClinicalView.jpeg'
import oralPathology from '../../images/OralPathology.jpeg'
import xrayView from '../../images/xrayView.jpeg'
import wisdomTooth from '../../images/wisdomTooth.jpeg'


const Cases = () => {
  return (
    <>
    <PageBanner pagename={"Cases"} info={"At Ivory Dental Clinic, we take pride in showcasing the expertise and dedication of our dental team through real-life cases. Each case demonstrates our commitment to providing high-quality, patient-centered care across a range of dental treatments. "} />
      <div className="casesContainer">
        <div className="case">
          <div className="case-img">
            <img src={implantPlacement} alt="" />
          </div>

          <div className="case-desc">
            <h3>Implant Placement</h3>
            <img className="line" src={Line} alt="" />
            <p>Dental implants are a permanent solution for missing teeth, providing both aesthetic and functional benefits. In this case, we successfully placed a dental implant in the patient’s jawbone, offering a sturdy foundation for a replacement tooth. The precision and care taken during the procedure ensure long-lasting results and improved oral health for the patient.</p>
          </div>
        </div>

        <div className="case">
          <div className="case-img">
            <img src={clinicalView} alt="" />
          </div>
          <div className="case-desc">
            <h3>Clinical View of Implant Placement</h3>
            <img className="line" src={Line} alt="" />
            <p>This image showcases the clinical perspective of the implant placement. Here, you can see the precise positioning of the implant within the gum tissue. Careful consideration of bone density, gum health, and implant placement technique ensures a successful outcome, promoting proper healing and long-term stability.</p>
          </div>
        </div>

        <div className="case">
          <div className="case-img">
            <img src={xrayView} alt="" />
          </div>
          <div className="case-desc">
            <h3>X-ray View of Implant Placement</h3>
            <img className="line" src={Line} alt="" />
            <p>The x-ray view provides a detailed look at how the implant integrates with the jawbone. This step is crucial for confirming the proper alignment and secure placement of the implant. As shown in the x-ray, the implant is perfectly positioned, demonstrating successful osseointegration (fusion with the bone) for a stable and lasting solution.</p>
          </div>
        </div>
        <div className="case">
          <div className="case-img">
            <img src={oralPathology} alt="" />
          </div>
          <div className="case-desc">
            <h3>Oral Pathology</h3>
            <img className="line" src={Line} alt="" />
            <p>Oral pathology involves diagnosing and treating diseases of the mouth. This case highlights the identification and management of a benign lesion within the patient’s oral cavity. With early detection and proper treatment, we ensure that oral health issues are resolved before they progress into more serious conditions. Our thorough approach ensures that every patient receives the care they need for a healthy mouth.</p>
          </div>
        </div>
        <div className="case">
          <div className="case-img">
            <img src={wisdomTooth} alt="" />
          </div>
          <div className="case-desc">
            <h3>Wisdom Tooth Extraction</h3>
            <img className="line" src={Line} alt="" />
            <p>Wisdom teeth can cause significant discomfort and lead to infections if not properly managed. In this case, we performed a successful wisdom tooth extraction to alleviate the patient’s pain and prevent future complications. The procedure was minimally invasive, with a focus on quick recovery and patient comfort. Post-operative care ensured that healing occurred smoothly, with minimal discomfort.</p>
          </div>
        </div>

      </div>

    </>
  )
}

export default Cases